/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');
        
        // Buttons w/ scroll
        $('.c-btn--scroll').click(function(e) {
          e.preventDefault();

          var section = $(this).attr('href');

          $('html, body').stop().animate({
            scrollTop: $(''+section+'').offset().top
          }, 1000);
          $('.c-nav, .c-trigger').removeClass('is-active');
        });

        // Mobile Menu
        $('.c-trigger').click(function(e) {
          e.preventDefault();

          $('.c-nav, .c-trigger').toggleClass('is-active');
        });


        // Find all YouTube videos
        var $allVideos = $("iframe[src^='https://www.youtube.com']"),
            $fluidEl = $(".o-col");

        // Figure out and save aspect ratio for each video
        $allVideos.each(function() {

          $(this)
            .data('aspectRatio', this.height / this.width)

            // and remove the hard coded width/height
            .removeAttr('height')
            .removeAttr('width');

        });

        // When the window is resized
        $(window).resize(function() {

          var newWidth = $fluidEl.width();

          // Resize all videos according to their own aspect ratio
          $allVideos.each(function() {

            var $el = $(this);
            $el
              .width(newWidth)
              .height(newWidth * $el.data('aspectRatio'));

          });
        // Kick off one resize to fix all videos on page load
        }).resize();


        // Navbar
        // Check if o-menu-holder exists
        if ($('.o-menu-holder').length) {
          // Make navbar transparent if hero exists
          $('.o-menu-holder').css('background-color', 'transparent');

          $(window).scroll(function(){
            var scrollPosition = $(this).scrollTop();

            if (scrollPosition >= 150) {
              $('.o-menu-holder').css('background-color', 'rgba(255, 255, 255,0.80)');
            } else {
              $('.o-menu-holder').css('background-color', 'transparent');
            }
          });
        } else {
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        // Testimonials w/ carousel
        if ($('.c-carousel--testimonials').length) {
          $('.c-carousel--testimonials').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoplayTimeout: 7000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            autoHeight: true
          });
        }
        // Prevent Default Action
        $('.c-service__anchor').click(function(e) {
            e.preventDefault();
        });
        
        // Services w/ lightbox
        $('.c-service__anchor').magnificPopup({
          type: 'inline',
          preloader: false,
          gallery: {
            enabled: true
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
